var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var productNewsKeys = {
    all: [{ scope: 'productNews' }],
    lists: function () { return [__assign(__assign({}, productNewsKeys.all[0]), { section: 'list' })]; },
    listProductNews: function (model) {
        return [__assign(__assign({}, productNewsKeys.lists()[0]), { entity: 'productNews', model: model })];
    },
    listRelatedProductNews: function (model) {
        return [
            __assign(__assign({}, productNewsKeys.lists()[0]), { entity: 'RelatedProductNews', model: model }),
        ];
    },
    listTypes: function () {
        return [__assign(__assign({}, productNewsKeys.lists()[0]), { entity: 'types' })];
    },
    detail: function (model) {
        return [__assign(__assign({}, productNewsKeys.all[0]), { section: 'detail', model: model })];
    },
    unreadableMark: function () {
        return [__assign(__assign({}, productNewsKeys.all[0]), { section: 'unreadable' })];
    },
};
export { productNewsKeys };
