import { useQuery, useQueryClient } from '@tanstack/react-query';
import { sortStringAlphabetically } from '@services/AppUtils';
import config from '@constants/parameters';
import { Translations as defaultTranslation } from '@constants/messages';
import { translationKeys } from './translationKeys';
var setList = function (translations, srt) {
    return Object.keys(translations)
        .filter(function (key) { return key.includes(srt); })
        .map(function (key) { return ({
        label: translations[key],
        value: key.split(srt)[1].toLowerCase(),
    }); })
        .sort(function (a, b) { return sortStringAlphabetically(a, b, 'label'); });
};
var useGetTranslation = function (_a) {
    var model = _a.model, onSuccess = _a.onSuccess, onError = _a.onError;
    var queryClient = useQueryClient();
    return useQuery(translationKeys.listMain(model), function () {
        return fetch("".concat(config.API_RESOURCES, "/localization/").concat(model.locale, ".json"), 
        //  switch to Local to test new translation
        // `${config.API_ROOT}/messages.json?culture=${isoCodeToLocale(lang)}`,
        {
            credentials: 'same-origin',
        })
            .then(function (response) { return response.json(); })
            .catch(function (e) {
            window.Messages = defaultTranslation;
            onError();
            return defaultTranslation;
        });
    }, {
        onSuccess: function (res) {
            if (onSuccess)
                onSuccess(res);
            queryClient.setQueryData(translationKeys.listCountries(), function () {
                return setList(res, 'Global.Country.');
            });
            queryClient.setQueryData(translationKeys.listLanguages(), function () {
                return setList(res, 'Global.Language.');
            });
        },
        staleTime: Infinity,
    });
};
export default useGetTranslation;
