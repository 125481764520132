var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState, createContext, } from 'react';
import moment from 'moment';
var KioskContext = createContext(null);
if (process.env.NODE_ENV === 'hotReload') {
    KioskContext.displayName = 'KioskContext';
}
var initaialState = {
    UniversalDateFrom: moment().startOf('day').format('YYYYMMDDHHmmss'),
    UniversalDateTo: moment().endOf('day').format('YYYYMMDDHHmmss'),
    DateFrom: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    DateTo: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
    kioskType: 'review',
    userAccountId: '',
    feederId: '',
    logicalDate: 'today',
    showDraft: false,
    showReview: true,
    showDeleted: false,
    reviewID: null,
    Skip: 0,
    Q: '',
    sortBy: 'createdDate',
};
function KioskProvider(props) {
    var _a = useState(initaialState), filterState = _a[0], setFilterState = _a[1];
    var setFilterType = function (kioskType) {
        setFilterState(function (prevState) { return (__assign(__assign({}, initaialState), { UniversalDateFrom: prevState.UniversalDateFrom, UniversalDateTo: prevState.UniversalDateTo, DateFrom: prevState.DateFrom, DateTo: prevState.DateTo, logicalDate: prevState.logicalDate, kioskType: kioskType })); });
    };
    return (_jsx(KioskContext.Provider
    // @ts-expect-error expect props to override value
    , __assign({ 
        // @ts-expect-error expect props to override value
        value: { filterState: filterState, setFilterState: setFilterState, setFilterType: setFilterType } }, props)));
}
export { KioskContext, KioskProvider };
