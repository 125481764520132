var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState, createContext, } from 'react';
import moment from 'moment';
var ProductNewsContext = createContext(null);
if (process.env.NODE_ENV === 'hotReload') {
    ProductNewsContext.displayName = 'ProductNewsContext';
}
function ProductNewsProvider(props) {
    var _a = useState({
        dateTo: moment().format('YYYY-MM-DD'),
        dateFrom: moment().add(-30, 'days').startOf('day').format('YYYY-MM-DD'),
        type: [1, 2],
        logicalDate: 'lastmonth',
        query: '',
        isRead: null,
        toggleFilter: 'disable-date-selector',
    }), productParams = _a[0], setProductParams = _a[1];
    return (_jsx(ProductNewsContext.Provider
    // @ts-expect-error expect props to override value
    , __assign({ 
        // @ts-expect-error expect props to override value
        value: { productParams: productParams, setProductParams: setProductParams } }, props)));
}
export { ProductNewsContext, ProductNewsProvider };
