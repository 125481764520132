import { useQuery } from '@tanstack/react-query';
import { postFetchQuery } from 'services/AppUtils';
import { pushmailKeys } from './pushmailKeys';
var useGetPushmailData = function (_a) {
    var model = _a.model;
    return useQuery(pushmailKeys.pushmailSubjects(model), function () {
        return postFetchQuery('/Clipping/GetPushmailData', model);
    });
};
export default useGetPushmailData;
