var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { IoIosSearch } from 'react-icons/io';
import Spinner from 'commons/Spinner';
import Select from 'commons/Select';
import Checkbox from 'commons/Checkbox';
import Input from 'commons/Input';
import { Wrapper } from './ShareArticleUserTable.styles';
var propTypes = {
    intl: PropTypes.object.isRequired,
    onUserSelect: PropTypes.func.isRequired,
    usersList: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedUsers: PropTypes.arrayOf(PropTypes.number).isRequired,
    loadingUsers: PropTypes.bool.isRequired,
};
var ShareArticleUserTable = /** @class */ (function (_super) {
    __extends(ShareArticleUserTable, _super);
    function ShareArticleUserTable(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            checkAll: false,
            selectedUsers: Array.isArray(props.selectedUsers)
                ? props.selectedUsers
                : [],
            show: 'all',
            userList: __spreadArray([], props.usersList, true),
            filterText: '',
        };
        _this.originalUserList = __spreadArray([], props.usersList, true);
        _this.handleCheckAllChange = _this.handleCheckAllChange.bind(_this);
        _this.handleCheckUserChange = _this.handleCheckUserChange.bind(_this);
        _this.handleVisibilityChange = _this.handleVisibilityChange.bind(_this);
        _this.handleFilterUsersChange = _this.handleFilterUsersChange.bind(_this);
        return _this;
    }
    ShareArticleUserTable.prototype.componentDidUpdate = function (prevProps, prevState) {
        var isNewProps = JSON.stringify(this.props) !== JSON.stringify(prevProps);
        if (isNewProps) {
            this.setState({
                userList: this.props.usersList,
                selectedUsers: this.props.selectedUsers,
            });
            this.originalUserList = __spreadArray([], this.props.usersList, true);
            this.handleVisibilityChange({ target: { value: this.state.show } }, {
                newState: __assign(__assign({}, this.state), { userList: this.props.usersList, selectedUsers: this.props.selectedUsers }),
            });
        }
    };
    ShareArticleUserTable.prototype.handleFilterUsersChange = function (value) {
        var userList = __spreadArray([], this.originalUserList, true);
        var filteredUsers = (Array.isArray(userList) &&
            userList
                .filter(function (user) {
                if (user.userName.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
                    user.email.toLowerCase().indexOf(value.toLowerCase()) > -1) {
                    return user;
                }
                return false;
            })
                .filter(function (v) { return v; })) ||
            [];
        this.setState({
            userList: filteredUsers,
            filterText: value,
        });
    };
    ShareArticleUserTable.prototype.handleVisibilityChange = function (e, params) {
        var value = e.target.value;
        var state = __assign({}, ((params === null || params === void 0 ? void 0 : params.newState) || this.state));
        var usersList = this.props.usersList;
        if (value) {
            switch (value) {
                case 'checked':
                    state.userList = __spreadArray([], usersList.filter(function (user) { var _a; return (_a = state.selectedUsers) === null || _a === void 0 ? void 0 : _a.includes(user.userId); }), true);
                    break;
                case 'unchecked':
                    state.userList = __spreadArray([], usersList.filter(function (user) { var _a; return !((_a = state.selectedUsers) === null || _a === void 0 ? void 0 : _a.includes(user.userId)); }), true);
                    break;
                default:
                    state.userList = __spreadArray([], usersList, true);
                    break;
            }
        }
        this.setState(__assign(__assign({}, state), { show: value }));
    };
    ShareArticleUserTable.prototype.handleCheckAllChange = function () {
        var _a = this.state, checkAll = _a.checkAll, userList = _a.userList;
        var onUserSelect = this.props.onUserSelect;
        var state = {};
        state.checkAll = !checkAll;
        if (checkAll) {
            state.selectedUsers = [];
        }
        else {
            state.selectedUsers = __spreadArray([], userList.map(function (user) { return user.userId; }), true);
        }
        onUserSelect(state.selectedUsers);
        this.setState(__assign({}, state));
    };
    ShareArticleUserTable.prototype.handleCheckUserChange = function (userId) {
        var _this = this;
        var selectedUsers = this.state.selectedUsers;
        var _a = this.props, usersList = _a.usersList, onUserSelect = _a.onUserSelect;
        var state = {
            selectedUsers: selectedUsers,
        };
        return function (event) {
            event.preventDefault();
            event.stopPropagation();
            if (!selectedUsers.includes(userId)) {
                state.selectedUsers.push(userId);
            }
            else {
                var index = state.selectedUsers.findIndex(function (id) { return id === userId; });
                state.selectedUsers.splice(index, 1);
            }
            _this.setState(__assign(__assign({}, selectedUsers), { checkAll: selectedUsers.length === usersList.length }), function () { return onUserSelect(selectedUsers); });
        };
    };
    ShareArticleUserTable.prototype.renderTable = function () {
        var _this = this;
        var _a = this.state, selectedUsers = _a.selectedUsers, userList = _a.userList;
        return (_jsx("div", { className: "usersList new-scrollbar", children: userList.map(function (user) { return (_jsxs("div", { role: "button", tabIndex: "0", className: selectedUsers.includes(user.userId) ? 'user checkedUser' : 'user', onClick: _this.handleCheckUserChange(user.userId), onKeyPress: _this.handleCheckUserChange(user.userId), "arial-label": "Select on user", children: [_jsx("div", { className: "checkUser", children: _jsx(Checkbox, { checked: selectedUsers.includes(user.userId), onChange: _this.handleCheckUserChange(user.userId) }) }), _jsx("div", { className: "username text-truncate", children: user.userName }), _jsx("div", { className: "email text-truncate", children: user.email })] }, user.userId)); }) }));
    };
    ShareArticleUserTable.prototype.render = function () {
        var state = __assign({}, this.state);
        var _a = this.props, loadingUsers = _a.loadingUsers, usersList = _a.usersList, formatMessage = _a.intl.formatMessage;
        return (_jsx(Wrapper, { className: "c-sharearticle-usertable", children: _jsxs("div", { className: "c-form-element", children: [_jsx("label", { className: "c-form-element__label", children: _jsx("strong", { children: _jsx(FormattedMessage, { id: "ShareArticles.labelUserslist" }) }) }), _jsxs("div", { className: "c-sharearticle-usertable__section", children: [_jsxs("div", { className: "c-sharearticle-usertable__bar-actions", children: [_jsx(Checkbox, { className: "checkAll", checked: state.checkAll ||
                                            state.selectedUsers.length === usersList.length, disabled: state.userList.length === 0, id: "checkAll", indeterminate: state.selectedUsers.length > 0 &&
                                            state.selectedUsers.length < usersList.length, onChange: this.handleCheckAllChange, children: _jsxs("span", { children: [_jsx(FormattedMessage, { id: "ShareArticles.usersList.checkAll" }), " (", state.userList.length, ")"] }) }), _jsxs("div", { className: "users-selection", children: [_jsxs("span", { children: [state.selectedUsers.length, " "] }), _jsx(FormattedMessage, { id: "ShareArticles.usersList.selectedUsers" })] }), _jsx("div", { className: "user-selection__filter", children: _jsx(Input, { onChange: this.handleFilterUsersChange, value: state.filterText, label: formatMessage({ id: 'AddWidget.filterLabel' }), showLabel: false, id: "test-id", placeholder: formatMessage({ id: 'AddWidget.filterLabel' }), left: true, hideBackground: true, iconLeft: IoIosSearch }) }), _jsx("div", { className: "visibility-options", children: _jsx(Select, { inputProps: { id: 'visibility-options' }, id: "visibility-options", onChange: this.handleVisibilityChange, hideBackground: true, hideBorder: true, options: [
                                                {
                                                    label: formatMessage({
                                                        id: 'ShareArticles.usersList.showAll',
                                                    }),
                                                    value: 'all',
                                                },
                                                {
                                                    label: formatMessage({
                                                        id: 'ShareArticles.usersList.checked',
                                                    }),
                                                    value: 'checked',
                                                },
                                                {
                                                    label: formatMessage({
                                                        id: 'ShareArticles.usersList.unchecked',
                                                    }),
                                                    value: 'unchecked',
                                                },
                                            ], value: state.show }) })] }), loadingUsers ? _jsx(Spinner, { size: "small" }) : this.renderTable()] })] }) }));
    };
    return ShareArticleUserTable;
}(Component));
ShareArticleUserTable.propTypes = propTypes;
export default injectIntl(ShareArticleUserTable, { forwardRef: true });
