import { useMutation } from '@tanstack/react-query';
import { postFetchQuery } from 'services/AppUtils';
var useSavePushmail = function () {
    return useMutation({
        mutationFn: function (model) {
            return postFetchQuery('/Common/SendPushmail', model);
        },
    });
};
export default useSavePushmail;
