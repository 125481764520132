var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, Fragment, forwardRef, useImperativeHandle, useContext, } from 'react';
import DayPicker from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import { useIntl } from 'react-intl';
import moment from 'moment';
import SearchStore from '@stores/SearchStore';
import PermissionStore from '@stores/PermissionStore';
import Svg from '@commons/Svg';
import { ModalBody } from '@commons/Modal';
import Button from '@commons/Button';
import Scroll from '@commons/Scroll';
import { SnackbarContext } from '@context/SnackbarContext';
import { zeroFill } from '@services/StringUtils';
import DateSelectorNavigator from './DateSelectorNavigator';
import { Wrapper } from './ModalCalendar.styles';
var defaultClassNames = {
    container: 'DayPicker',
    wrapper: 'DayPicker-wrapper',
    interactionDisabled: 'DayPicker--interactionDisabled',
    months: 'DayPicker-Months',
    month: 'DayPicker-Month',
    navBar: 'DayPicker-NavBar',
    navButtonPrev: 'DayPicker-NavButton DayPicker-NavButton--prev',
    navButtonNext: 'DayPicker-NavButton DayPicker-NavButton--next',
    navButtonInteractionDisabled: 'DayPicker-NavButton--interactionDisabled',
    caption: 'DayPicker-Caption',
    weekdays: 'DayPicker-Weekdays',
    weekdaysRow: 'DayPicker-WeekdaysRow',
    weekday: 'DayPicker-Weekday',
    body: 'DayPicker-Body',
    week: 'DayPicker-Week',
    weekNumber: 'DayPicker-WeekNumber',
    day: 'DayPicker-Day',
    footer: 'DayPicker-Footer',
    todayButton: 'DayPicker-TodayButton',
    // default modifiers
    today: 'today',
    selected: 'selected',
    disabled: 'disabled',
    outside: 'outside',
};
var currentYear = new Date().getFullYear();
var fromMonth = new Date(currentYear, 0);
var ModalCalendar = forwardRef(function (_a, ref) {
    var _b = _a.functional, functional = _b === void 0 ? false : _b, onChange = _a.onChange, reset = _a.reset, onClose = _a.onClose, dates = _a.dates, className = _a.className, comeFrom = _a.comeFrom;
    var formatMessage = useIntl().formatMessage;
    var setSnackbarObj = useContext(SnackbarContext).setSnackbarObj;
    var _c = useState({
        from: undefined,
        minuteFrom: '00',
        minuteTo: '59',
        hourFrom: '00',
        hourTo: '23',
        modalIsOpen: false,
        month: fromMonth,
        to: undefined,
        monthNumber: 2,
        resizeRef: 0,
    }), state = _c[0], setState = _c[1];
    var from = state.from, to = state.to, hourFrom = state.hourFrom, hourTo = state.hourTo, minuteFrom = state.minuteFrom, minuteTo = state.minuteTo, modalIsOpen = state.modalIsOpen, month = state.month, resizeRef = state.resizeRef;
    moment.locale(window.km.getLang());
    moment.updateLocale(window.km.getLang(), {
        week: {
            dow: 1, // 1 corresponds to Monday
        },
    });
    useEffect(function () {
        if (!functional) {
            SearchStore.addChangeListener(onSearchChange);
            populateDates();
        }
        onResizeWindow();
        window.addEventListener('resize', onResizeWindow);
        return function () {
            window.removeEventListener('resize', onResizeWindow);
            SearchStore.removeChangeListener(onSearchChange);
        };
    }, []);
    var onResizeWindow = function () {
        setState(function (prevState) { return (__assign(__assign({}, prevState), { resizeRef: window.innerWidth })); });
    };
    var onSearchChange = function () {
        populateDates();
    };
    var populateDates = function () {
        var _a, _b;
        var searchModel = SearchStore.searchModel;
        var ll = comeFrom
            ? JSON.stringify(dates)
            : SearchStore.getLastPeriod();
        var lastPeriod = null;
        try {
            lastPeriod = JSON.parse(ll);
        }
        catch (error) {
            lastPeriod = {
                from: '',
                to: '',
                logicalDate: '1',
            };
        }
        if (lastPeriod) {
            lastPeriod.from =
                dates && !lastPeriod.from
                    ? dates.universalDateFrom || ''
                    : ((_a = lastPeriod.from) === null || _a === void 0 ? void 0 : _a.replace(' ', 'T')) || lastPeriod.from;
            lastPeriod.to =
                dates && !lastPeriod.to
                    ? dates.universalDateTo || ''
                    : ((_b = lastPeriod.to) === null || _b === void 0 ? void 0 : _b.replace(' ', 'T')) || lastPeriod.to;
        }
        if ((lastPeriod === null || lastPeriod === void 0 ? void 0 : lastPeriod.logicalDate) === '5') {
            setState(function (prevState) { return (__assign(__assign({}, prevState), { month: new Date(lastPeriod.to), from: new Date(lastPeriod.from), to: new Date(lastPeriod.to), minuteFrom: new Date(lastPeriod.from).getMinutes().toString(), hourFrom: new Date(lastPeriod.from).getHours().toString(), minuteTo: new Date(lastPeriod.to).getMinutes().toString(), hourTo: new Date(lastPeriod.to).getHours().toString() })); });
        }
        else {
            setState(function (prevState) { return (__assign(__assign({}, prevState), { month: new Date(new Date().getFullYear(), new Date().getMonth()), from: comeFrom && dates
                    ? new Date(dates.universalDateFrom)
                    : new Date(searchModel.DateFrom), to: comeFrom && dates
                    ? new Date(dates.universalDateTo)
                    : new Date(searchModel.DateTo) })); });
        }
    };
    // eslint-disable-next-line
    var handlesetDates = function (_a) {
        var dateMode = _a.dateMode, universalDateFrom = _a.universalDateFrom, universalDateTo = _a.universalDateTo;
        if (dateMode === 'custom') {
            setState(function (prevState) { return (__assign(__assign({}, prevState), { month: moment(universalDateTo, 'YYYYMMDDHHmmss').toDate(), from: moment(universalDateFrom, 'YYYYMMDDHHmmss').toDate(), to: moment(universalDateTo, 'YYYYMMDDHHmmss').toDate() })); });
        }
        else {
            setState(function (prevState) { return (__assign(__assign({}, prevState), { month: new Date(new Date().getFullYear(), new Date().getMonth()) })); });
        }
    };
    var handleOpenModal = function () {
        setState(function (prevState) { return (__assign(__assign({}, prevState), { modalIsOpen: true })); });
    };
    var handleCloseModal = function (dates, type) {
        populateDates();
        setState(function (prevState) { return (__assign(__assign({}, prevState), { modalIsOpen: false })); });
        if (typeof reset === 'function') {
            reset();
        }
        if (typeof onClose === 'function') {
            onClose(type === 'validation' ? dates : undefined);
        }
    };
    var handleDayClick = function (day) {
        var newTo = state.to, newFrom = state.from;
        if (newFrom && !to) {
            newTo = day;
        }
        else if (newFrom && to) {
            newFrom = day;
            newTo = undefined;
        }
        else {
            newFrom = day;
        }
        if ((newTo && newTo.getTime() > newFrom.getTime()) ||
            (newFrom && !newTo)) {
            setState(function (prevState) { return (__assign(__assign({}, prevState), { from: newFrom, to: newTo })); });
        }
        else {
            setState(function (prevState) { return (__assign(__assign({}, prevState), { from: newTo, to: newFrom })); });
        }
    };
    var handleResetClick = function () {
        setState(function (prevState) { return (__assign(__assign({}, prevState), { from: undefined, to: undefined })); });
    };
    var handleYearMonthChange = function (date) {
        setState(function (prevState) { return (__assign(__assign({}, prevState), { month: date })); });
    };
    var handleMinutesChange = function (type) {
        var selectedMinute = type === 'from' ? 'minuteFrom' : 'minuteTo';
        return function (ev) {
            var date = state[type];
            var value = ev.target.value;
            var selectedDate = type === 'from' ? 'from' : 'to';
            date === null || date === void 0 ? void 0 : date.setMinutes(Number(value));
            setState(function (prevState) {
                var _a;
                return (__assign(__assign({}, prevState), (_a = {}, _a[selectedDate] = date, _a[selectedMinute] = value, _a)));
            });
        };
    };
    var handleHoursChange = function (type) {
        // type can be 'from' or 'to';
        var selectedHour = type === 'from' ? 'hourFrom' : 'hourTo';
        return function (ev) {
            var date = state[type];
            var value = ev.target.value;
            var selectedDate = type === 'from' ? 'from' : 'to';
            date === null || date === void 0 ? void 0 : date.setHours(Number(value));
            setState(function (prevState) {
                var _a;
                return (__assign(__assign({}, prevState), (_a = {}, _a[selectedDate] = date, _a[selectedHour] = value, _a)));
            });
        };
    };
    var handleValidationClick = function () {
        if (typeof onChange !== 'function') {
            return;
        }
        if (!from) {
            setSnackbarObj({
                open: true,
                status: 'info',
                message: formatMessage({ id: 'Calendar.emptyDateWarning' }),
            });
            return;
        }
        var formattedFrom = moment(from)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            .set({ hour: hourFrom, minute: minuteFrom, second: 0 })
            .format('YYYY-MM-DD HH:mm:ss');
        var formattedTo = moment(to || from)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            .set({ hour: hourTo, minute: minuteTo, second: 0 })
            .format('YYYY-MM-DD HH:mm:ss');
        var dates = {
            universalDateFrom: (from &&
                moment
                    .utc(formattedFrom)
                    .format((functional && 'YYYYMMDDHHmmss') || 'YYYY-MM-DD HH:mm:ss')) ||
                null,
            universalDateTo: (formattedTo &&
                moment
                    .utc(formattedTo)
                    .format((functional && 'YYYYMMDDHHmmss') || 'YYYY-MM-DD HH:mm:ss')) ||
                null,
        };
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment, @typescript-eslint/no-confusing-void-expression
        if (typeof onChange() === 'function') {
            // @ts-expect-error TODO: refactor this logic
            // eslint-disable-next-line
            onChange('custom', dates)();
        }
        else {
            onChange('custom', dates);
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        handleCloseModal(dates, 'validation');
    };
    var renderHours = function (type, id) {
        var selectedHour;
        if (type === 'from') {
            selectedHour = hourFrom;
        }
        else {
            selectedHour = hourTo;
        }
        return (_jsxs(Fragment, { children: [_jsx("label", { htmlFor: id, className: "hidden", children: selectedHour }), _jsx("select", { className: "m-left--xx-small", onChange: handleHoursChange(type), value: zeroFill(Number(selectedHour), 2), id: id, "aria-label": "hours", children: Array.from(Array(24).keys()).map(function (hour, index) { return (_jsx("option", { value: zeroFill(index, 2), children: zeroFill(index, 2) }, hour)); }) })] }));
    };
    var renderMinutes = function (type, id) {
        var selectedMinute;
        if (type === 'from') {
            selectedMinute = minuteFrom;
        }
        else {
            selectedMinute = minuteTo;
        }
        return (_jsxs(Fragment, { children: [_jsx("label", { htmlFor: id, className: "hidden", children: selectedMinute }), _jsx("select", { className: "m-left--xx-small", onChange: handleMinutesChange(type), value: zeroFill(Number(selectedMinute), 2), id: id, "aria-label": "selected-minutes", children: Array.from(Array(60).keys()).map(function (minute, index) { return (_jsx("option", { value: zeroFill(index, 2), children: zeroFill(index, 2) }, minute)); }) })] }));
    };
    useImperativeHandle(ref, function () { return ({
        // @ts-expect-error: Expect the params to be passed from the parrent
        handlesetDates: handlesetDates,
        handleOpenModal: handleOpenModal,
        handleResetClick: handleResetClick,
    }); });
    var retentionPeriod = PermissionStore.getRetentionPeriod();
    var modifiers = { start: from, end: to };
    var classNames = __assign(__assign({}, defaultClassNames), { selected: 'selected primary-bg-color' });
    var retention = retentionPeriod instanceof Date
        ? new Date(retentionPeriod.getFullYear(), retentionPeriod.getMonth(), retentionPeriod.getDate())
        : undefined;
    var todayDate = new Date().getDate();
    var disabledModifiers = retention
        ? {
            after: new Date(retention.getFullYear(), retention.getMonth(), 0),
            before: new Date(retention.getFullYear(), retention.getMonth(), todayDate),
        }
        : null;
    return (_jsxs(Wrapper, { isOpen: modalIsOpen, onCancel: function () {
            handleCloseModal();
        }, maxContent: true, className: "".concat(className, " c-date-selector__modal"), children: [_jsx("div", { className: "close_Modal", children: _jsxs(Button, { variant: "text", onClick: function () {
                        handleCloseModal();
                    }, title: "Close button", className: "c-date-selector__modal-close", children: [_jsx(Svg, { icon: "close", size: "small" }), _jsx("span", { className: "hidden", children: "close modal" })] }) }), _jsx(ModalBody, { children: _jsxs(Scroll, { className: "new-scrollbar", children: [_jsx(DayPicker, { month: month, disabledDays: disabledModifiers || undefined, fromMonth: retention, className: "c-date-picker-range__calendar", classNames: classNames, numberOfMonths: resizeRef < 1200 ? 1 : 2, modifiers: modifiers, firstDayOfWeek: 1, selectedDays: [from, { from: from, to: to }], onDayClick: handleDayClick, showOutsideDays: true, fixedWeeks: true, locale: window.km.getLang(), localeUtils: MomentLocaleUtils, 
                            // onMonthChange={handleMonthChange}
                            captionElement: function (_a) {
                                var date = _a.date;
                                return (_jsx(DateSelectorNavigator, { retentionPeriod: retention, date: date, onChange: handleYearMonthChange }));
                            } }), _jsx("div", { className: "date_period", children: _jsxs("div", { className: "date-selector__inputs", children: [_jsx("div", { className: "date-selector__title m-bottom--x-large", children: formatMessage({ id: 'SearchBar.dateSelector.custom' }) }), _jsx("div", { className: "m-bottom--medium", children: _jsxs("div", { className: "date-selector__date align-bottom", children: [_jsxs("div", { className: "date-selector__row m-bottom--medium", children: [_jsxs("div", { className: "date-selector__from", children: [_jsx("div", { className: "date-selector__label p-bottom--x-small", children: formatMessage({
                                                                        id: 'DateSelector.labelDateStart',
                                                                    }) }), _jsx("div", { className: "date-selector__value", children: _jsx("div", { className: "date-selector__date--from p-horizontal-x-small", children: from &&
                                                                            window.moment
                                                                                .utc(from)
                                                                                .local()
                                                                                .format('DD/MM/YYYY') }) })] }), _jsxs("div", { className: "date-selector__to", children: [_jsx("div", { className: "date-selector__label p-bottom--x-small p-left--small", children: formatMessage({ id: 'DateSelector.labelTimeStart' }) }), _jsxs("div", { className: "minute_Hour_block", children: [_jsx("div", { className: "date-selector__value p-left--x-small", children: renderHours('from', 'hoursFromSelect') }), _jsx("div", { className: "date-selector__value p-left--x-small", children: renderMinutes('from', 'minutesFromSelect') })] })] })] }), _jsxs("div", { className: "date-selector__row m-bottom--medium", children: [_jsxs("div", { className: "date-selector__from", children: [_jsx("div", { className: "date-selector__label p-bottom--x-small", children: formatMessage({ id: 'DateSelector.labelDateEnd' }) }), _jsx("div", { className: "date-selector__value", children: _jsx("div", { className: "date-selector__date--from p-horizontal-x-small", children: to &&
                                                                            window.moment
                                                                                .utc(to)
                                                                                .local()
                                                                                .format('DD/MM/YYYY') }) })] }), _jsxs("div", { className: "date-selector__to", children: [_jsx("div", { className: "date-selector__label p-bottom--x-small p-left--small", children: formatMessage({ id: 'DateSelector.labelTimeEnd' }) }), _jsxs("div", { className: "minute_Hour_block", children: [_jsx("div", { className: "date-selector__value p-left--x-small", children: renderHours('to', 'hoursToSelect') }), _jsx("div", { className: "date-selector__value p-left--x-small", children: renderMinutes('to', 'minutesToSelect') })] })] })] })] }) }), _jsxs("div", { className: "footer", children: [_jsx("div", { className: "footer_button", children: _jsx(Button, { variant: "outline", className: "p-horizontal--large cancel_button", onClick: function () {
                                                        handleCloseModal();
                                                    }, title: "Cancel", children: formatMessage({ id: 'Global.cancel' }) }) }), _jsx("div", { className: "footer_button", children: _jsx(Button, { variant: "fill", color: "secondary", className: "p-horizontal--large pull--right ", onClick: handleValidationClick, title: "Validate button", children: formatMessage({ id: 'Global.ok' }) }) })] })] }) })] }) })] }));
});
ModalCalendar.displayName = 'ModalCalendar';
export default ModalCalendar;
