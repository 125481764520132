import { useQuery } from '@tanstack/react-query';
import { postFetchQuery } from 'services/AppUtils';
import { productNewsKeys } from './productNewsKeys';
var useGetUnreadCountProductNews = function (_a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.enabled, enabled = _c === void 0 ? false : _c;
    return useQuery(productNewsKeys.unreadableMark(), function () { return postFetchQuery('/api/productNews/GetUnReadNewsCount'); }, {
        enabled: enabled,
        select: function (data) { return data.unReadCount; },
    });
};
export default useGetUnreadCountProductNews;
