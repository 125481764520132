import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { LoaderCard, LoaderRow, LoaderDraft } from './variants';
import { Wrapper } from './LoaderPlaceholder.styles';
var loaders = {
    card: LoaderCard,
    row: LoaderRow,
    cardDraft: LoaderDraft,
};
function LoaderPlaceholder(_a) {
    var _b = _a.ready, ready = _b === void 0 ? false : _b, children = _a.children, _c = _a.variant, variant = _c === void 0 ? 'row' : _c;
    var Loader = loaders[variant];
    return ready ? (children || _jsx(_Fragment, {})) : (_jsx(Wrapper, { children: _jsx(Loader, {}) }));
}
export default LoaderPlaceholder;
