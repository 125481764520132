import ActionTypes from '@constants/actionTypes';
import Dispatcher from '@services/AppDispatcher';
var serverActionCreators = {
    receiveAddClip: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.ADD_CLIP_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveAddComment: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.ADD_COMMENT_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveTranslateText: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.TRANSLATE_CLIP_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveAddConsumption: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.ADD_CONSUMPTION_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveEvaluateConsumption: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.ADD_EVALUATE_CONSUMPTION_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveSaveCodification: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.SAVE_EVALUATION_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetSupportContacts: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_SUPPORT_CONTACTS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveSendEmailForSupportContacts: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.SEND_EMAIL_SUPPORT_CONTACTS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveAssignToMe: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.ASSIGN_TO_ME_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveUnlockClips: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.UNLOCK_CLIPS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveAddHighlight: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.ADD_HIGHLIGHT_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveAddToDraft: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.ADD_TO_DRAFT_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveAddWidget: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.ADD_WIDGET_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveInsightsAddWidget: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.ADD_INSIGHTS_WIDGET_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveCreateWidgetAsMyQuickWidget: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.CREATE_WIDGET_AS_MY_QUICK_WIDGET_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetDashboardInfo: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_DASHBOARD_INFO_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveCategorizeClip: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.CATEGORIZE_CLIP_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveCreateClipOrder: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.CREATE_CLIP_ORDER_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveCreateReview: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.CREATE_REVIEW_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveCreateUnitPDF: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.CREATE_UNIT_PDF_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receivePdfTemplates: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_PDF_TEMPLATES_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveUpdateReviewPdfTemplate: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.UPDATE_REVIEW_PDF_TEMPLATE_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveCreateReviewPDF: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.CREATE_REVIEW_PDF_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveCreatePDF: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.CREATE_PDF_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveCreateZip: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.CREATE_ZIP_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveCreateExcel: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.CREATE_EXCEL_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetEditingRequestState: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_EDITING_REQUEST_STATE,
            errors: errors,
            json: json,
        });
    },
    receiveUserConfirmation: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.USER_CONFIRMATION_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveDeleteComment: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.DELETE_COMMENT_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveDeleteDraft: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.DELETE_DRAFT_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveDuplicateDraft: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.DUPLICATE_DRAFT_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveDeleteHighlight: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.DELETE_HIGHLIGHT_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveDenyArticle: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.DENY_ARTICLE_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetAllClips: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_ALL_CLIPS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetClip: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_CLIP_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveSetClipSelectedTab: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.SET_CLIP_SELECTED_TAB_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetClips: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_CLIPS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetClipsRead: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_CLIPS_READ_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetClipSelections: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_CLIPSELECTIONS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetClipSelectionsByClipIds: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_CLIP_SELECTIONS_BY_CLIP_IDS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetEvaluation: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_EVALUATION_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetCustomerMetrics: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_CUSTOMERMETRICS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetMediaImpactScore: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_MEDIA_IMPACT_SCORE_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetCurrentUser: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_CURRENT_USER_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetDraft: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_DRAFT_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetDraftReview: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_DRAFT_REVIEW_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetClipsForDraft: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_CLIPS_FOR_DRAFT_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetDrafts: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_DRAFTS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveInsightGetFacetItems: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_INSIGHT_FACET_ITEMS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveExcelHeaderItems: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_EXCEL_HEADER_ITEMS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetFulltextClip: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_FULLTEXT_CLIP_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetHierarchies: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_HIERARCHIES_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetHierarchy: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_HIERARCHY_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetMediaClip: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_MEDIA_CLIP_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetMediaClipNewsdigest: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_MEDIA_CLIP_NEWS_DIGEST_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetNextClips: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_NEXT_CLIPS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetRelatedClips: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_RELATED_CLIPS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetReview: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_REVIEW_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetReviewPdf: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_REVIEW_PDF_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetReviewPdfState: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_REVIEW_PDF_STATE_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetReviews: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_REVIEWS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetNewsDigest: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_NEWSDIGEST_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveAccessNewsDigestByType: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.ACCESS_NEWSDIGEST_BY_TYPE_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetNewsDigestPdf: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_NEWSDIGEST_PDF_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetNewsDigestPdfState: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_NEWSDIGEST_PDF_STATE_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetNewsDigests: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_NEWSDIGESTS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receivesetNewsDigestSelectedTab: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.SET_NEWSDIGESTSELECTED_TAB_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetInforgraphy: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_INFOGRAPHIE_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetDashboardSnapshot: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_DASHBOARD_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetTranslations: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_TRANSLATIONS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetWidgets: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_WIDGETS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetPushmailData: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_PUSHMAIL_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetUsersByGroups: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_USERS_BY_GROUPS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveSavePushmailData: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.SAVE_PUSHMAIL_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetVisualization: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_VISUALIZATION_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetPermissions: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_PERMISSIONS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveRemoveFromDraft: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.REMOVE_FROM_DRAFT_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveSaveDraft: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.SAVE_DRAFT_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveSaveInsightFiltersUser: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.SAVE_INSIGHTS_FILTERS_USER_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveSaveHierarchy: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.SAVE_HIERARCHY_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveDeleteHierarchy: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.DELETE_HIERARCHY_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receivesetSelectedTab: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.SET_SELECTED_TAB_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveUpdateClip: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.UPDATE_CLIP_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveUpdateComment: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.UPDATE_COMMENT_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveUpdateDraft: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.UPDATE_DRAFT_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveUpdateLike: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.UPDATE_LIKE_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveUpdateLikes: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.UPDATE_LIKES_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveUpdateFavourite: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.UPDATE_FAVOURITE_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveUpdateFavourites: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.UPDATE_FAVOURITES_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveUpdateSentiments: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.UPDATE_SENTIMENTS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveUpdateTrash: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.UPDATE_TRASH_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveUpdateUserListPreference: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.UPDATE_USER_LIST_PREFERENCE_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetDraftUserPreferences: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_DRAFT_USER_PREFERENCE_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveResetSession: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.RESET_SESSION_CLIP_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetQueryBuilderFields: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_QUERYBUILDER_FIELDS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetQueryBuilderFieldValues: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_QUERYBUILDER_FIELD_VALUES_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetSourcesType: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_SOURCE_TYPES_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetFavouriteSearches: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_FAVOURITE_SEARCHES_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveCreateFavoriteSearches: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.CREATE_FAVOURITE_SEARCH_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveUpdateFilter: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.UPDATE_FILTER_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveUpdateFavoriteSearch: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.UPDATE_FAVORITE_SEARCH_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveDeleteFavoriteSearch: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.DELETE_FAVORITE_SEARCH_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetDashboards: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_DASHBOARDS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetDashboardTabulations: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_DASHBOARD_TABS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetTabulationWidgets: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_TABULATION_WIDGETS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveUpdateDashboardName: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.UPDATE_DASHBOARD_NAME_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveDeleteDashboard: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.REMOVE_DASHBOARD_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetUserProfileData: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_USER_PROFILE_DATA_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveUpdateUserProfileData: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.SAVE_USER_PROFILE_DATA_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveUpdateUserPassword: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.UPDATE_USER_PASSWORD_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveAddTab: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.ADD_TAB_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveAddDashboard: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.ADD_DASHBOARD_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveUpdateTab: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.UPDATE_TAB_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveDownloadTabPdf: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.DOWNLOAD_TAB_PDF_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveDownloadTabImage: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.DOWNLOAD_TAB_IMAGE_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveDownloadTabPowerpoint: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.DOWNLOAD_TAB_POWERPOINT_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveDuplicateDashboard: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.DUPLICATE_DASHBOARD_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGenerateDuplicateDashboardName: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GENERATE_DUPLICATE_DASHBOARD_NAME_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGenerateDuplicateTabulationName: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GENERATE_DUPLICATE_TABULATION_NAME_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetWidgetData: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_WIDGET_DATA_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetWidgetDrillDownData: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_WIDGET_DRILLDOWN_DATA_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveNextWidgetDrillDownData: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_NEXT_WIDGET_DRILLDOWN_DATA_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetWidgetMetrics: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_WIDGET_DIMENSIONS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetQuickWidgets: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_QUICK_WIDGETS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveDeleteTabulation: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.DELETE_TAB_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveSetDefaultDashboard: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.SET_DEFAULT_DASHBOARD_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveSetTabulationOrder: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.SET_TABULATION_ORDER_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveCopyTabulation: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.COPY_TABULATION_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveRenameTabulation: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.RENAME_TABULATION_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveSetWidgetOrder: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.SORT_WIDGETS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveDeleteWidget: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.DELETE_WIDGET_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveCopyWidget: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.COPY_WIDGET_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveUpdateWidgetSettings: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.UPDATE_WIDGET_SETTINGS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetNextTimelineData: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_NEXT_TIMELINE_DATA_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveDashBoardHash: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_HASH_DASHBOARD_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetAdvancedFilterMenu: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_ADVANCED_FILTER_MENU_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    // receiveGetInsightAdvancedFilterMenu(json, errors) {
    //   Dispatcher.handleServerAction({
    //     actionType: ActionTypes.GET_INSIGHT_ADVANCED_FILTER_MENU_RESPONSE,
    //     errors,
    //     json,
    //   });
    // },
    receiveGetCreateWidgetModel: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_CREATE_WIDGET_MODEL_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveCreateWidget: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.CREATE_WIDGET_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveUpdateWidget: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.UPDATE_WIDGET_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetWidgetFilterSearchDate: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_WIDGET_FILTER_SEARCH_DATE_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetAllClipSelections: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_ALL_CLIP_SELECTIONS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetAllCountries: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_ALL_COUNTRIES_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetAllServices: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_ALL_SERVICES_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetLang: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_LANGUAGES_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetMicrosoftLang: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_MICROSOFT_LANGUAGES_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveExportPdfSnapshotDashboard: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_PDF_SNAPSHOT_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveExportDashboardSnapshot: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_DASHBOARD_SNAPSHOT_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetKiosks: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_KIOSKS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetKioskReview: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_KIOSK_REVIEW_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    restoreGetKioskReview: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.RESTORE_KIOSK_REVIEW_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetKioskNewsDigest: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_KIOSK_NEW_DIGEST_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveEditKioskReview: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.EDIT_KIOSK_REVIEW_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveEditKioskNewDigest: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.EDIT_KIOSK_NEW_DIGEST_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveEditKioskInfography: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.EDIT_KIOSK_INFOGRAPHY_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveEditKioskDashboard: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.EDIT_KIOSK_DASHBORD_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveRemoveKioskDashboard: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.REMOVE_KIOSK_DASHBORD_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveRemoveKioskInfography: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.REMOVE_KIOSK_INFOGRAPHY_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveRemoveKioskReview: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.REMOVE_KIOSK_REVIEW_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveRemoveKioskNewDigest: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.REMOVE_KIOSK_NEWS_DIGEST_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveAccessReviewByType: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.ACESS_REVIEW_BY_TYPE_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveDeleteKiosks: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.REMOVE_KIOSKS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveUpdateKiosks: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.EDIT_KIOSKS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetAccountList: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_ACCOUNTS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetAgencyLogin: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_AGENCY_LOGIN_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetUserLoginInfo: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_LOGIN_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetLogout: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_LOGOUT_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveUpdateFavorite: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.UPDATE_ACCOUNT_FAVORITE_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetCustomMetrics: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_ALL_CUSTOM_METRICS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveAddCustomMetrics: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.ADD_CUSTOM_METRICS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveActivateDeactivateCustomMetrics: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.ACTIVATE_DEACTIVATE_CUSTOM_METRICS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetCustomerMetricActiveValues: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_CUSTOM_METRICS_ACTIVE_VALUES_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetCustomerMetricInactiveValues: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_CUSTOM_METRICS_INACTIVE_VALUES_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveAddCustomMetricsValues: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.ADD_CUSTOM_METRICS_VALUES_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveActivateDesactivateCustomerMetricValues: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.ACTIVATE_DEACTIVATE_CUSTOM_METRICS_VALUES_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveUpdateCustomerMetric: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.UPDATE_CUSTOM_METRIC_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveUpdateCustomerMetrics: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.UPDATE_CUSTOM_METRICS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveUpdateCustomerMetricValues: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.UPDATE_CUSTOM_METRICS_VALUES_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetCustomerMetricRanges: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_CUSTOM_METRICS_RANGES_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveAddCustomMetricsRanges: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.ADD_CUSTOM_METRICS_RANGES_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveUpdateCustomerMetricRanges: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.UPDATE_CUSTOM_METRICS_RANGES_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receivegetNewsBanners: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_NEWS_BANNERS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receivegetIsSSOPage: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_IS_LOGIN_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetUserCountries: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_USER_COUNTRIES_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetProductNews: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_PRODUCT_NEWS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetProductNewsTypes: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_PRODUCT_NEWS_TYPES_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetProductNewsById: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_PRODUCT_NEWS_BY_ID_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveRelatedProductNews: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_RELATED_PRODUCT_NEWS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveMarkAllProductsAsRead: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.MARK_ALL_PRODUCT_NEWS_AS_READ_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveUnreadableCountProductNews: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.MARK_UNREADABLE_COUNT_PRODUCT_NEWS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetPercolators: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_ALL_PERCOLATORS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetPercolatorRetroactively: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_ALL_PERCOLATORS_RETROACTIVELY_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveSavePercolatorRetroactively: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.SAVE_PERCOLATORS_RETROACTIVELY_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveDeletePercolatorRetroactively: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.DELETE_PERCOLATORS_RETROACTIVELY_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveCancelPercolatorRetroactively: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.CANCEL_PERCOLATORS_RETROACTIVELY_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetQueryStringPercolator: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_QUERY_STRING_PERCOLATOR_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveActivateDesactivatePercolator: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.ACTIVATE_DESACTIVATE_PERCOLATOR_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveDeletePercolator: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.DELETE_PERCOLATOR_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveInsertPercolator: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.INSERT_PERCOLATOR_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveTestPercolator: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.TEST_PERCOLATOR_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveCheckPercolator: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.CHECK_PERCOLATOR_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetAutoTaggingInfos: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_Auto_TAGGING_INFOS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetAllFilters: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_ALL_FILTERS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveGetCookiesAndPrivacy: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_COOKIES_AND_PRIVACY_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveGetUsersGroupsProfile: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_USERS_GROUP_PROFILE_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveGetGroupsRolesCategoriesByUserAccount: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_GROUPS_ROLES_BY_USER_ACCOUNT_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveDeleteUser: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_DELETE_USER_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveInsertUser: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_INSERT_NEW_USER_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveGetProfiles: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_PROFILES_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveGetExportedInfoUsersListsExcel: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_EXPORTED_INFO_USERS_LISTS_EXCEL_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveGetInfoGroupsList: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_INFO_GROUPS_LIST_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveUpdateGroupUserMembership: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.UPDATE_GROUP_USER_MEMBERSHIP_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveGetEmailAlerts: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_EMAIL_ALERTS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveActivateDesactivateEmailAlert: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.ACTIVATE_DEACTIVATE_EMAIL_ALERTS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveDeleteEmailAlert: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.DELETE_EMAIL_ALERTS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveGetEmailAlertsPushMailLayout: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_EMAIL_ALERTS_PUSHMAIL_LAYOUT_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveGetEmailAlertsUserClipSelection: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_EMAIL_ALERTS_USER_CLIP_SELECTION_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveGetEmailAlert: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_EMAIL_ALERT_BY_ID_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveUpdateEmailAlert: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.UPDATE_EMAIL_ALERT_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveCreateEmailAlert: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.INSERT_EMAIL_ALERT_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveUpdateBranding: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.UPDATE_USER_BRANDING_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveGetCategories: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_CATEGORIES_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveGetCategoriyGroups: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_CATEGORIES_GROUPS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveGetCategoryGroupsMembers: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_CATEGORIES_GROUPS_MEMBERS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveAddClipSelection: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.ADD_CLIP_SELECTION_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveUpdateClipSelection: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.UPDATE_CLIP_SELECTION_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveDeleteClipSelection: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.DELETE_CLIP_SELECTION_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveSetClipSelectionAuthorization: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.SET_CLIP_SELECTION_AUTHORIZATION_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveGetIncidentMessage: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_INCIDENT_MESSAGE_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveGetCopyrightDeclarations: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_COPYRIGHT_DECLARATIONS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveGetTopVisualizationsBySource: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_TOP_VISUALIZATIONS_BY_SOURCE_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveUpdateCopyrightDeclarations: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.UPDATE_COPYRIGHT_DECLARATION_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveGetTopVisualizationsByClip: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_TOP_VISUALIZATIONS_BY_CLIP_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveGetTopVisualizationsByDate: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_TOP_VISUALIZATIONS_BY_DATE_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveGetDistributions: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_DISTRIBUTIONS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveGetDistributionMembers: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_DISTRIBUTIONS_MEMBERS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveGetDistributionAuthorizations: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_DISTRIBUTIONS_CATEGORIES_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveGetProfileById: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_PROFILE_BY_ID_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveUpdateProfileRoles: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.UPDATE_PROFILE_ROLES_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveCreateProfile: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.CREATE_PROFILE_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveUpdateProfile: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.UPDATE_PROFILE_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveDeleteProfile: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.DELETE_PROFILE_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveCreateDistribution: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.CREATE_DISTRIBUTION_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveUpdateDistribution: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.UPDATE_DISTRIBUTION_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveDeleteDistribution: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.DELETE_DISTRIBUTION_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveDeleteGroupMember: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.DELETE_GROUP_MEMBER_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveDeleteUserProfile: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.DELETE_USER_PROFILE_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveUpdateUserProfile: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.UPDATE_USER_PROFILE_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveCreateHierarchy: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.CREATE_HIERARCHY_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveChangeUserAccountStatus: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.CHANGE_USER_ACCOUNT_STATUS_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveGetWidgetInforgraphy: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_WIDGET_INFOGRAPHY_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveInsertInfographySettings: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.INSERT_INFOGRAPHY_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveConvertInfoGraphyToLink: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.CONVERT_INFOGRAPHY_TO_LINK_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveConvertInfoGraphyToPdf: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.CONVERT_INFOGRAPHY_TO_PDF_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveGetInforgraphyByHash: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.GET_INFOGRAPPHY_BY_HASH_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveRemoveInfography: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.DELETE_INFOGRAPHY_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    receiveUpdateClipsAlsoIn: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.UPDATE_REVIEW_CLIPS_ALSO_IN_RESPONSE,
            errors: errors,
            json: json,
        });
    },
    recieveUpdateUsername: function (json, errors) {
        Dispatcher.handleServerAction({
            actionType: ActionTypes.UPDATE_USERNAME_RESPONSE,
            errors: errors,
            json: json,
        });
    },
};
export default serverActionCreators;
