var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var pushmailKeys = {
    all: [{ scope: 'pushmail' }],
    list: function () { return [__assign(__assign({}, pushmailKeys.all[0]), { section: 'list' })]; },
    pushmailSubjects: function (model) {
        return [__assign(__assign({}, pushmailKeys.list()[0]), { entity: 'subject', model: model })];
    },
    visualization: function (model) {
        return [__assign(__assign({}, pushmailKeys.all[0]), { section: 'visualization', model: model })];
    },
};
export { pushmailKeys };
